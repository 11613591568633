@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/respond-to';
@import '~styles/tools/mixins/get-font-styles';

.header {
  align-items: center;
  background-color: get-color(psi);
  color: get-color(beta);
  display: flex;
  position: sticky;
  user-select: none;
  z-index: 900;
  height: 88px;
  overflow: visible;
}

.navigation {
  align-items: center;
  background-color: get-color(psi);
  color: get-color(beta);
  display: flex;
  position: sticky;
  user-select: none;
  z-index: 899;
  height: 88px;
  overflow: hidden;
}

.header {
  top: 0;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 1128px;
  width: 100%;
}

.navigation {
  align-items: center;
  background-color: get-color(psi, 0, 0.7);
  display: flex;
  height: 56px;
  top: 88px;

  nav {
    max-width: 1128px;
    margin: 0 auto;
    width: 100%;
  }

  a {
    color: get-color(beta);
    text-decoration: none;
    margin-right: spacing(3);
  }
}

.menuButton {
  padding: 8px;
  transform: translateX(-8px);
  line-height: 0;
  margin-right: spacing(3);
}

.logotype {
  margin-right: 5px;

  img {
    width: 96px;
    height: auto;
  }
}

.logotypeAnchor {
  display: block;
  width: 140px;
}

.logo {
  display: block;
  width: 100%;
  height: 40px;
}

.shop {
  display: flex;
  flex-direction: row;

  a,
  button {
    align-items: center;
    color: get-color(beta);
    display: flex;
    text-decoration: none;
    margin-left: spacing(3);

    i {
      margin-left: spacing(1);
    }
  }
}

.cart {
  background-color: get-color(beta, 0, 0.2);
  border-radius: 4px;
  padding: spacing(0, 1.5);

  i {
    font-size: 19px;
  }
}

.user {
  align-items: center;
  background: none;
  color: get-color(beta);
  display: flex;
  margin-left: spacing(3);

  span {
    white-space: nowrap;
  }
}

.actAsRetailerContainer {
  align-self: flex-end;
}
