@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.block {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.root {
  @include get-font-styles(stockholm);
  color: get-color(primary);
}

.groupLabel {
  @include get-font-styles(copenhagen);
  line-height: 32px;
  margin-bottom: spacing(1);
}

.required {
  @include get-font-styles(denver);
  color: get-color(gamma);
  margin-left: spacing(0.5);
}
