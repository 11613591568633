@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.collapsible {
  background-color: get-color(lambda);
  border: none;

  position: relative;
}

.collapsibleHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  p {
    margin-bottom: 0;
  }
}

.contentStyle {
  &:not(:empty) {
    border: none;
  }
}

.tabStyle {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 40px;
    background: linear-gradient(0deg, #f8f8f8, rgba(248, 248, 248, 0));
    bottom: 100%;
  }
}

.bubble {
  margin: spacing(1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: spacing(2);

  position: static;
  width: 45%;

  background: get-color(beta);
  border-radius: 4px;
}

.bold {
  font-weight: bold;
}
