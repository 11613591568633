@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';

.root {
  background-color: get-color(beta);
  border-radius: 4px;
  overflow: hidden;
}

.header,
.content {
  padding: spacing(1, 1.5);
}

.header {
  align-items: center;
  background: none;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
}

.content {
  margin-bottom: spacing(-2);
}
