@import '~styles/tools/functions/spacing';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  padding: 0 spacing(2);
}

.selectHolder {
  select {
    margin-bottom: 0;
  }
}
