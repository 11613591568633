@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1338;
  overflow: visible;
}

.modal {
  :global(.ReactModal__Overlay) {
    background-color: transparent;
    transition: ease(background-color, lightning, out-quad);
  }

  :global(.ReactModal__Overlay--after-open) {
    background-color: rgba(0, 0, 0, 0.5);
    transition: ease(background-color, lightning, out-quad);
  }

  :global(.ReactModal__Overlay--before-close) {
    background-color: transparent;
    transition: ease(background-color, lightning, out-quad, lightning);
  }

  :global(.ReactModal__Content) {
    opacity: 0;
    transition: ease(opacity, lightning, out-quad), ease(transform, lightning, out-quad);

    &.bottomAnchor {
      transform: translateY(50px);
    }

    &.centerAnchor {
      transform: translateY(50px);
    }

    &.leftAnchor {
      transform: translateX(-50px);
    }

    &.rightAnchor {
      transform: translateX(50px);
    }

    &.topAnchor {
      transform: translateY(-50px);
    }
  }

  :global(.ReactModal__Content--after-open) {
    opacity: 1;
    transition: ease(opacity, lightning, out-quad, lightning), ease(transform, lightning, out-quad, lightning);

    &.bottomAnchor {
      transform: translateY(0);
    }

    &.centerAnchor {
      transform: translateY(0);
    }

    &.leftAnchor {
      transform: translateX(0);
    }

    &.rightAnchor {
      transform: translateX(0);
    }

    &.topAnchor {
      transform: translateY(0);
    }
  }

  :global(.ReactModal__Content--before-close) {
    opacity: 0;
    pointer-events: none;
    transition: ease(opacity, lightning, out-quad), ease(transform, lightning, out-quad);

    &.bottomAnchor {
      transform: translateY(50px);
    }

    &.centerAnchor {
      transform: translateY(50px);
    }

    &.leftAnchor {
      transform: translateX(-50px);
    }

    &.rightAnchor {
      transform: translateX(50px);
    }

    &.topAnchor {
      transform: translateY(-50px);
    }
  }
}

.modalContent {
  background-color: get-color(beta);
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow-y: auto;
  /* ugly outline, necessary? */
  outline: 0;

  &.bottomAnchor {
    bottom: 0;
    left: 0;
    right: 0;
    height: 606px;
  }

  &.centerAnchor {
    margin: auto;
    position: relative;
  }

  &.leftAnchor {
    left: 0;
    top: 0;
    width: 468px;
  }

  &.rightAnchor {
    right: 0;
    top: 0;
    width: 468px;
  }

  &.topAnchor {
    top: 0;
    left: 0;
    right: 0;
    height: 468px;
  }
}
