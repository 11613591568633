::-webkit-input-placeholder {
  color: map-get($colors, eta);
  opacity: 1;
}

:-moz-placeholder {
  color: map-get($colors, eta);
  opacity: 1;
}

::-moz-placeholder {
  color: map-get($colors, eta);
  opacity: 1;
}

:-ms-input-placeholder {
  color: map-get($colors, eta);
  opacity: 1;
}
