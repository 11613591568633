@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/aspect-ratio';
@import '~styles/tools/mixins/get-font-styles';

.filters {
  display: flex;
}

.filters {
  flex-direction: column;
}

.filter {
  display: flex;
  padding-bottom: spacing(2);

  > div {
    background-color: get-color(beta);
    border-radius: 4px;
    flex: 1;
    overflow: hidden;
  }

  .content {
    padding: spacing(1, 1.5);
  }
}

.filter {
  select {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.test {
  text-overflow: ellipsis;
}
