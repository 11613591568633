@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.root {
  border-radius: spacing(0.5);
  display: inline-block;
  padding: spacing(0, 0.75);
}

@each $name, $color in $colors {
  .#{$name}Color {
    background-color: $color;
    border-color: $color;
    color: get-color(beta);
  }
}
