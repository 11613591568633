@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.block {
  display: inline-flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  &.withPrefix,
  &.withSuffix {
    flex-direction: row;

    input {
      flex: 1;

      .txtPrefix &,
      .txtSuffix & {
        z-index: 1;
      }
    }
  }

  &.txtPrefix {
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.txtSuffix {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.prefix,
.suffix {
  height: 40px;
  position: absolute;
  top: 0;

  > * {
    width: auto;
    height: 100%;
    object-fit: contain;
  }

  .txtPrefix &,
  .txtSuffix & {
    align-items: center;
    background-color: get-color(iota);
    display: flex;
    padding-left: spacing(1.5);
    padding-right: spacing(1.5);
    position: relative;
  }
}

.prefix {
  border-radius: spacing(0.5, 0, 0, 0.5);
  left: 0;

  .prefix-lg & {
    height: 40px;
  }

  .prefix-md & {
    height: 24px;
    left: 8px;
    top: 8px;
  }

  .prefix-sm & {
    height: 18px;
    left: 11px;
    top: 11px;
  }
}

.suffix {
  border-radius: spacing(0, 0.5, 0.5, 0);
  right: 0;

  .suffix-lg & {
    height: 40px;
  }

  .suffix-md & {
    height: 24px;
    right: 8px;
    top: 8px;
  }

  .suffix-sm & {
    height: 18px;
    right: 11px;
    top: 11px;
  }
}

.required {
  @include get-font-styles(denver);
  color: get-color(gamma);
  margin-left: spacing(0.5);
}

input {
  .prefix-lg & {
    padding-left: spacing(4);
  }

  .prefix-md & {
    padding-left: spacing(5);
  }

  .prefix-sm & {
    padding-left: spacing(4.5);
  }

  .suffix-lg & {
    padding-right: spacing(4);
  }

  .suffix-md & {
    padding-right: spacing(5);
  }

  .suffix-sm & {
    padding-right: spacing(4.5);
  }
}

.root {
  @include get-font-styles(stockholm);
  background-color: get-color(epsilon);
  border: 2px solid transparent;
  border-radius: spacing(0.5);
  color: get-color(primary);
  line-height: 20px;
  padding: spacing(1) spacing(1.5);
  margin-bottom: 8px;

  &.bordered {
    background-color: get-color(beta);
    border: 1px solid get-color(kappa);
    line-height: 22px;
  }

  &:focus {
    border-color: get-color(alpha);
    box-shadow: 0 0 0 3px get-color(zeta);
    outline: 0;
  }

  &:hover {
    &:not(:focus) {
      border-color: get-color(zeta);
      color: #696969;
    }
  }

  &.error {
    border-color: get-color(gamma);
  }
}

label {
  @include get-font-styles(copenhagen);
  color: get-color(primary);
  margin-bottom: spacing(0.5);
}

.error {
  color: get-color(gamma);
}

.labelSupplement {
  margin-left: spacing(0.5);
  @include get-font-styles(rome);
}
