@import '~styles/settings/variables';
@import '~styles/tools/functions/spacing';

.root {
  align-items: center;
  display: flex;
  padding: spacing(3, 0);

  > a {
    text-decoration: none;
  }

  > i {
    font-size: 13px;
    margin: spacing(0, 1);
  }
}

.breadcrumbMenuIcon {
  :global(.open) & {
    transform: translateX(-5px) rotate(180deg) !important;
  }
}
