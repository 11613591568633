@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/functions/spacing';

.cellWrapper {
  display: flex;
}

.messagesCell {
  justify-content: flex-start;
  position: relative;

  .messageIcon {
    color: get-color(eta);
  }

  .quantityWrapper {
    position: absolute;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    left: 15px;
    top: -5px;
    border-width: 0;
    background-color: get-color(eta);

    .quantity {
      width: 100%;
      text-align: center;
      font-size: 11px;
      font-weight: 500;
      color: get-color(beta);
      position: absolute;
      top: -2px;
      left: 0.5px;
    }
  }
}

.editTextblock {
  input {
    width: 100%;
    margin: 0;
    padding: 2px 0;
  }
}

.statusCell {
  border-radius: spacing(0.5);
  background-color: get-color(tau);
  text-align: center;
  padding: spacing(0, 1);

  &.omicron {
    background-color: get-color(omicron);
    color: get-color(primary);
  }

  &.rho {
    background-color: get-color(rho);
    color: get-color(pi);
  }

  &.nu {
    background-color: get-color(nu);
    color: get-color(xi);
  }

  &.sigma {
    background-color: get-color(sigma);
    color: get-color(omega);
  }

  &.tau {
    background-color: get-color(tau);
    color: get-color(gamma);
  }

  &.epsilon {
    background-color: get-color(epsilon);
    color: get-color(xi);
  }
}

.roleCell {
  border-radius: spacing(0.5);
  background-color: get-color(tau);
  text-align: center;
  padding: spacing(0, 1);
  color: get-color(beta);

  &.DEALY_ADMIN {
    background-color: get-color(workshop);
  }

  &.DEALY_SELLER {
    background-color: get-color(zeta);
  }

  &.RETAILER_ADMIN {
    background-color: get-color(superuser);
  }

  &.SUPER_ADMIN {
    background-color: get-color(support);
  }

  &.RETAILER_SELLER {
    background-color: get-color(seller);
  }

  &.RETAILER_USER {
    background-color: get-color(support);
  }

  &.RETAILER_WORKSHOP {
    background-color: get-color(workshop);
  }

  &.INSURANCE_USER {
    background-color: get-color(insurance);
  }
}

.rowTypeCell {
  border-radius: spacing(0.5);
  background-color: get-color(tau);
  text-align: center;
  padding: spacing(0, 1);
  background-color: get-color(upsilon);
  color: get-color(theta);
}

.rowStatusCell {
  border-radius: spacing(0.5);
  background-color: get-color(tau);
  text-align: center;
  padding: spacing(0, 1);

  &.WAITING_FOR_SPARE_PART &.WAITING_FOR_WORKSHOP {
    background-color: get-color(omicron);
    color: get-color(primary);
  }

  &.REGISTERED {
    background-color: get-color(rho);
    color: get-color(pi);
  }

  &.COMPLEMENT {
    background-color: get-color(nu);
    color: get-color(xi);
  }

  &.APPROVED {
    background-color: get-color(sigma);
    color: get-color(omega);
  }

  &.PROCESSING {
    background-color: get-color(tau);
    color: get-color(gamma);
  }

  &.DECLINED {
    background-color: get-color(tau);
    color: get-color(gamma);
  }

  &.UPDATED {
    background-color: get-color(tau);
    color: get-color(gamma);
  }
}

.invoiceStatusCell {
  border-radius: spacing(0.5);
  background-color: get-color(omicron);
  text-align: center;
  padding: spacing(0, 1);

  &.UNKNOWN {
    background-color: get-color(omicron);
    color: get-color(primary);
  }

  &.PAYED {
    background-color: get-color(sigma);
    color: get-color(omega);
  }

  &.NOT_PAYED {
    background-color: get-color(tau);
    color: get-color(gamma);
  }
}

.oldValue {
  color: get-color(gamma);
  text-decoration: line-through;
}

.orderStatusCell {
  border-radius: spacing(0.5);
  background-color: get-color(tau);
  text-align: center;
  padding: spacing(0, 1);

  &.PROCESSING {
    background-color: get-color(phi);
    color: get-color(psi);
  }

  &.ALLOCATED,
  &.PARTIALLY_ALLOCATED {
    background-color: get-color(nu);
    color: get-color(xi);
  }

  &.BLOCKED {
    background-color: get-color(tau);
    color: get-color(gamma);
  }

  &.DELIVERED,
  &.PARTIALLY_DELIVERED {
    background-color: get-color(rho);
    color: get-color(theta);
  }

  &.INVOICED,
  &.PARTIALLY_INVOICED {
    background-color: get-color(sigma);
    color: get-color(omega);
  }
}

.orderItemStatusCell {
  border-radius: spacing(0.5);
  background-color: get-color(tau);
  text-align: center;
  padding: spacing(0, 1);

  &.SENT {
    background-color: get-color(sigma);
    color: get-color(omega);
  }

  &.AT_RETAILER {
    background-color: get-color(tau);
    color: get-color(gamma);
  }

  &.PROCESSING {
    background-color: get-color(nu);
    color: get-color(xi);
  }
}

.expandableCellOpen {
  display: flex;
  flex-direction: column;
  white-space: normal;
}

.expandableCellClosed {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.expandableCellToggle {
  align-self: flex-end;
}

.invoiceTypeCell {
  border-radius: spacing(0.5);
  background-color: get-color(omicron);
  text-align: center;
  padding: spacing(0, 1);

  &.PRO_FORMA {
    background-color: get-color(omicron);
    color: get-color(primary);
  }

  &.CREDIT_NOTE {
    background-color: get-color(sigma);
    color: get-color(omega);
  }

  &.CREDIT_NOTE_WARRANTY {
    background-color: get-color(rho);
    color: get-color(pi);
  }

  &.INVOICE {
    background-color: get-color(nu);
    color: get-color(xi);
  }

  &.INTEREST_INVOICE {
    background-color: get-color(rhu);
    color: get-color(pi);
  }

  &.NOT_SET_IN_SAGE {
    background-color: get-color(tau);
    color: get-color(gamma);
  }
}

.infoIcons {
  display: flex;
  cursor: default;
  user-select: none;
  gap: spacing(1);
  color: get-color(alpha);

  :global(.material-icons-outlined) {
    font-size: 24px;
  }
}

.tableIcon {
  display: block;
}

.operationContainer {
  display: flex;
  justify-content: flex-start;
}

.circle {
  border-radius: 50%;
  width: spacing(3.5);
  height: spacing(3.5);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: get-color(epsilon);
  margin: spacing(0, 0.5);

  > a {
    line-height: 0;
  }

  :global(.material-icons-outlined) {
    font-size: 20px;
  }
}
