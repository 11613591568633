@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.button {
  width: 100%;
}

.row {
  display: flex;
  padding: spacing(0, 3, 2, 3);

  &.full {
    margin-left: spacing(-3);
    margin-right: spacing(-3);
  }

  &.columnRow {
    flex-direction: column;
  }
}

.endRow {
  justify-content: flex-end;
  margin-top: auto;
  padding-bottom: 0;
  padding-top: spacing(3);
}

.title {
  @include get-font-styles(rio);
  line-height: 30px;
  margin: spacing(4, 0, 2);
}

.titleRow {
  border-bottom: 1px solid get-color(epsilon);
  justify-content: space-between;
  padding: spacing(3);
}

.editRow {
  background-color: get-color(epsilon);
  justify-content: space-between;
  padding: spacing(1.5, 3);

  > span {
    @include get-font-styles(copenhagen);
    line-height: spacing(4);
  }
}

.bold {
  font-weight: bold;
}

.label {
  display: flex;
  flex-direction: column;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.category {
  @include get-font-styles(copenhagen);
  width: 33%;
}

.tableButton {
  @include expand-hitarea(1);
  background: none;
  color: get-color(alpha);
}

.closeButton {
  padding-left: 0;
  padding-right: 0;
}

.cancelButton,
.editButton,
.saveButton {
  display: flex;
  line-height: 20px;

  :global(.material-icons),
  :global(.material-icons-outlined) {
    font-size: 20px;
    margin-left: spacing(1);
  }
}

.cancelButton {
  margin-left: auto;
}

.editButton {
  padding-right: spacing(1);
}

.saveButton {
  margin-left: spacing(1);
}

.padHorizontal {
  padding-left: spacing(3);
  padding-right: spacing(3);
}

.padVertical {
  padding-bottom: spacing(3);
  padding-top: spacing(3);
}

.padBottom {
  padding-bottom: spacing(3);
}

.padTop {
  padding-top: spacing(3);
}

.asLink {
  color: get-color('alpha');
  text-decoration: underline;
  margin: spacing(0, 1, 3, 0);
}

.reactSelectContainer {
  flex: 1;

  :global {
    .react-select__control {
      background-color: get-color(epsilon);
      height: 40px;
      box-sizing: border-box;
    }

    .react-select__control:hover {
      border-color: get-color(fi);
    }
    /* stylelint-disable selector-max-compound-selectors */
    .react-select__control--is-disabled {
      .react-select__indicators {
        visibility: hidden;
      }
    }
    /* stylelint-enable selector-max-compound-selectors */
    .react-select__dropdown-indicator {
      color: get-color(primary);
    }

    .react-select__option--is-selected {
      background-color: get-color(alpha);
    }

    .react-select__option--is-focused {
      background-color: get-color(phi);
      color: get-color(primary);
    }

    .react-select__dropdown-indicator:hover {
      color: unset;
    }
  }
}
