@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';

.root {
  background-color: get-color(lambda);
  padding: spacing(4) 0;
  margin: 0 auto;
  max-width: 1128px;
  width: 100%;
}

.spinnerHolder {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: spacing(6);
}

.spinner {
  // Size of spinner
  top: 25px;
  left: 25px;
}
