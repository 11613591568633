@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.root {
  background-color: get-color(beta);
  border: 1px solid get-color(epsilon);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 100%;
  left: 0;
  max-width: 385px;
  overflow-y: auto;
  padding-top: spacing(6);
  position: fixed;
  top: 0;
  width: 290px;
  pointer-events: none;
  visibility: hidden;
  transform: translate3d(-290px, 0, 0);
  z-index: 1;

  &.shop {
    padding-top: spacing(18);
  }

  &:not(.hidden) {
    transition: ease(transform, fast, out-quad), ease(visibility, fast, linear, fast);
  }

  :global(.menuOpen) & {
    pointer-events: initial;
    transform: translate3d(0, 0, 0);
    transition: ease(transform, fast, out-quad), ease(visibility, fast, linear);
    visibility: visible;
  }

  :global(.menuInstant) & {
    transition-duration: 0s !important;
  }
}

.modal {
  // Need to use !important because DealyModal has nested selectors
  width: 324px !important;
}

.modalTop {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  padding: spacing(3, 3, 0, 3);
}

.modalHeading {
  flex: 1;
  @include get-font-styles(rio);
  margin: 0;
  padding-right: spacing(2);
}

.modalClose {
  display: block;

  i {
    display: block;
  }
}

.userWrapper {
  align-items: center;
  border-bottom: 1px solid get-color(epsilon);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: spacing(4, 3, 3);

  > p {
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: spacing(1);
    }
  }
}

.userInitial {
  align-items: center;
  background-color: get-color(iota);
  border-radius: 50%;
  display: flex;
  height: spacing(6);
  justify-content: center;
  margin-bottom: spacing(0.75);
  width: spacing(6);

  p {
    @include get-font-styles(rio);
    color: get-color(eta);
    margin: 0;
    text-transform: uppercase;
  }
}

.name {
  @include get-font-styles(stockholm);
}

.role {
  @include get-font-styles(copenhagen);
  opacity: 0.5;
}

.link {
  display: flex;

  > span > i {
    margin-left: spacing(1);
    font-size: 20px;
  }
}

.navigation {
  padding: spacing(4, 0);
}

.navItem {
  margin-bottom: spacing(2);
  padding: 0 spacing(3);

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    border-left-color: get-color(alpha);
    border-left-style: solid;
    border-left-width: 5px;

    a {
      color: get-color(primary);
      margin-left: -5px;
    }
  }

  a {
    @include get-font-styles(copenhagen);
    @include expand-hitarea(0.75);
    color: get-color(eta);
    text-decoration: none;

    svg {
      margin-left: spacing(1);
      vertical-align: middle;
    }
  }
}

.settingsWrapper {
  border-top: 1px solid get-color(epsilon);
  margin-top: auto;
  padding: spacing(4, 0);
}
