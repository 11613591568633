@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.box {
  flex: 1;
  min-height: 6em;
  background-color: get-color(beta);
  margin: spacing(0, 0, 2, 0);
  padding: spacing(2);

  span {
    @include get-font-styles(copenhagen);
    color: get-color(eta);
  }
}

.title {
  @include get-font-styles(denver);

  &__blue {
    color: get-color(alpha);
  }
}

.price {
  @include get-font-styles(copenhagen);

  &__blue {
    color: get-color(alpha);
  }
}

.notInStockDisclaimer {
  margin-top: spacing(1);

  p {
    color: get-color(gamma);
  }

  li {
    margin-top: spacing(1);
  }

  span {
    @include get-font-styles(copenhagen);
    color: get-color(primary);
  }
}

.flexContainer {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  justify-content: space-between;
}

.orderButton {
  width: 100%;
  margin: spacing(2, 0, 1, 0);
}

.totals {
  margin-top: spacing(3);
}
