@import '~styles/settings/variables';
@import '~styles/tools/mixins/aspect-ratio';
@import '~styles/tools/mixins/respond-to';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/functions/get-color';

.root {
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
  max-height: 550px;
}

.absolute {
  position: absolute;
}

.content {
  width: 100%;
}

.glimmer {
  background-image: url('~assets/images/glimmer.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 440px auto;
  width: inherit;
  height: 132px;
  align-items: center;
  display: flex;
  justify-content: center;

  i {
    font-size: 36px;
    opacity: 0.54;
  }
}

.title,
.message {
  text-align: center;
}

.title {
  color: get-color(primary);
}

.message {
  color: get-color(eta);
}
