@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/mixins/respond-to';
@import '~styles/tools/mixins/get-font-styles';

.root {
  align-items: center;
  background-color: get-color(psi);
  color: get-color(beta);
  display: flex;
  position: sticky;
  top: 0;
  user-select: none;
  z-index: 999;
  height: map-get($header-heights, navbar-down);
  overflow: hidden;

  @include respond-to('navbar') {
    height: map-get($header-heights, navbar-up);
  };
}

.menuButton {
  padding: 8px;
  transform: translateX(-8px);
  line-height: 0;
}

.logotype {
  margin-right: 5px;

  // @include respond-to(navbar) {
  //   width: 400px;
  // }

  svg {
    display: block;
    width: map-get($header-heights, navbar-down);
    height: map-get($header-heights, navbar-down);
    fill: get-color(beta);
    padding: 5px 0;

    @include respond-to('navbar') {
      width: map-get($header-heights, navbar-up);
      height: map-get($header-heights, navbar-up);
    };
  }
}

.logotypeAnchor {
  display: block;
  width: 140px;
}

.logo {
  display: block;
  width: 100%;
  height: 40px;
}

.navLink {
  display: block;
  text-decoration: none;
  text-align: center;
  @include get-font-styles(oslo);
  line-height: map-get($header-heights, navbar-down);
  padding: 0 15px;
  font-weight: 700;
  margin-left: 1px;
  color: get-color(beta);

  &:hover {
    background-color: get-color(beta);
    color: get-color(alpha);
  }

  @include respond-to('navbar') {
    line-height: map-get($header-heights, navbar-up);
  };
}

.navLinkActive {
  background-color: get-color(beta);
  color: get-color(alpha);
}

.nameWrapper {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  height: 100%;
}
