@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.block {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-wrap: wrap;

  &.label-left {
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

    input {
      margin-top: 5px;
    }

    label {
      margin-right: spacing(1);
    }
  }

  &.label-right {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;

    input {
      margin-top: 5px;
    }

    label {
      margin-left: spacing(1);
    }
  }

  &.label-under {
    flex-direction: column-reverse;

    input {
      margin-top: 5px;
    }
  }

  &.doNotWrap {
    flex-wrap: nowrap;
  }
}

.root {
  @include get-font-styles(stockholm);
  background-color: get-color(epsilon);
  border: 1px solid get-color(primary);
  border-radius: 2px;
  color: get-color(primary);
  outline: none;
  padding: 11px 15px;
  margin-bottom: 8px;

  &:focus {
    border-color: get-color(alpha);
  }

  &:hover {
    border-color: get-color(alpha);
  }

  &.error {
    border-color: get-color(gamma);
  }
}

label {
  @include get-font-styles(copenhagen);
  color: get-color(primary);
  line-height: 32px;
}

.error {
  color: get-color(gamma);
}
