@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.buttonGroup {
  display: flex;
  justify-content: flex-end;
}

.missingAddress {
  color: get-color(fi);
}

.textField {
  input {
    background-color: get-color(phi);
  }
}

.flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.editButton {
  @include get-font-styles(copenhagen);
  margin: spacing(0, 0, 0, 2);

  &__noBorder {
    max-height: 2.5em;
    background-color: transparent;
    border: transparent;
    @include get-font-styles(copenhagen);
    color: get-color(alpha);
  }
}

.errorMessage {
  color: get-color(gamma);
}
