@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.wrapper {
  justify-content: space-between;
  padding: spacing(2, 2, 2, 0);
  max-width: 700px;
}

.row {
  display: flex;
  padding: spacing(0, 2, 1, 2);
}

.column {
  display: flex;
  padding: spacing(0, 3, 2, 3);
  flex-direction: column;
}

.category {
  @include get-font-styles(copenhagen);
  width: 33%;
}

.equipment {
  background-color: get-color(psi);
  padding: spacing(0, 0.5);
  border-radius: 4px;
  color: get-color(beta);
  margin-right: spacing(1);
}

.requestWrapper {
  display: flex;
  flex-direction: column;
  padding: spacing(0, 3, 2, 3);

  .textArea {
    height: 150px;
  }

  .checkboxWrapper {
    margin: spacing(1, 0, 2,);
  }
}

.link {
  color: get-color(alpha);
}

.line {
  border-width: 1px;
  border-color: get-color(kappa);
  margin-left: spacing(3);
}

.title {
  margin-bottom: spacing(0.5);

  &.red {
    color: get-color(fi);
  }

  &.article {
    color: get-color(pi);
    margin-bottom: spacing(0.5);
  }
}

.originalPrice {
  text-decoration-line: line-through;
  margin-left: spacing(2);
}
