@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.root {
  @include get-font-styles(copenhagen);
  text-align: left;
  padding: spacing(1, 2);
  background-color: get-color(beta);
  margin-bottom: 1px;
  line-height: 24px;

  &:hover {
    background-color: get-color(phi);
  }
}
