@import '~styles/settings/variables';
@import '~styles/tools/functions/spacing';

.modal {
  max-width: 300px;
  max-height: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: spacing(2.5);
  gap: spacing(2.5);
}

.titleRow {
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    padding: 0;
  }
}

.downloadButton {
  align-self: end;
  padding: spacing(0.5, 2);
}
