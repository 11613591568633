@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/respond-to';
@import '~styles/tools/mixins/get-font-styles';

.root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid get-color(omicron);
  padding: spacing(3);
}

.heading {
  flex: 1;
  @include get-font-styles(rio);
  margin: 0;
  padding-right: spacing(2);
}

.buttonContainer {
  align-self: center;
}

.close {
  display: block;

  i {
    display: block;
  }
}

.middle {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: spacing(3);
}

.empty {
  min-height: 200px;
  width: 90%;
  margin: 0 auto;
}

.bottom {
  flex: 0 0 auto;
  padding: 0 spacing(3);
  position: relative;

  &::before {
    content: '';
    background-color: get-color(omicron);
    height: 1px;
    position: absolute;
    top: 0;
    right: spacing(3);
    left: spacing(3);
  }
}

.bottomSummarize {
  padding: spacing(4) 0;
}

.bottomColumn {
  @include get-font-styles(colombo);
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: spacing(2);
  }
}

.bottomColumnTotal {
  font-weight: 500;
}

.bottomColumnTotalSale {
  font-weight: 500;
  color: get-color(alpha);
}

.bottomColumnText {
  flex: 1;
}

.bottomColumnPrice {
  padding-left: spacing(2);
}

.bottomButton {
  padding-bottom: spacing(3);
}
