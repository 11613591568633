@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

$arrow-size: 4px;

.trigger {
  display: inline-block;
  background-color: transparent;
}

.tooltip {
  @include get-font-styles(samarkand);
  z-index: 1400;
  max-width: 350px;
  padding: spacing(1);
  pointer-events: none;
  user-select: none;
  backface-visibility: hidden;
}

.inner {
  border-radius: 5px;
  padding: spacing(0.5, 1);
  color: get-color(beta);
  background-color: get-color(primary);
  box-shadow: 2px 2px 4px get-color(primary, 0, 0.4);
}

.arrow {
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }
}

.tooltip[data-popper-placement*='top'] .arrow {
  bottom: spacing(1);

  &::after {
    top: 0;
    left: 0;
    margin-left: -$arrow-size;
    border-right: $arrow-size solid transparent;
    border-left: $arrow-size solid transparent;
  }
}

.tooltip[data-popper-placement*='right'] .arrow {
  left: spacing(1);

  &::after {
    top: 0;
    right: 0;
    margin-top: -$arrow-size;
    border-top: $arrow-size solid transparent;
    border-bottom: $arrow-size solid transparent;
  }
}

.tooltip[data-popper-placement*='bottom'] .arrow {
  top: spacing(1);

  &::after {
    bottom: 0;
    left: 0;
    margin-left: -$arrow-size;
    border-right: $arrow-size solid transparent;
    border-left: $arrow-size solid transparent;
  }
}

.tooltip[data-popper-placement*='left'] .arrow {
  right: spacing(1);

  &::after {
    top: 0;
    left: 0;
    margin-top: -$arrow-size;
    border-top: $arrow-size solid transparent;
    border-bottom: $arrow-size solid transparent;
  }
}
