@import '~styles/settings/variables';
@import '~styles/tools/mixins/respond-to';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/spacing';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @each $breakpoint, $padding in $container-paddings {
    @if ($breakpoint == 'root') {
      padding: spacing(0, 0, $padding, 0);
    } @else {
      @include respond-to($breakpoint) {
        padding: spacing(0, 0, $padding, 0);
      };
    }
  };

  section {
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: ease(margin-left, fast, out-quad);

    &:global(.no-flex) {
      flex: 0;
    }

    :global(.menuInstant) & {
      transition-duration: 0s !important;
    }
  }
}

:global(.menuOpen) {
  &:not(:global(.menuOverlay)) {
    aside ~ section {
      margin-left: 290px;
    }
  }
}
