@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.block {
  display: inline-flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.required {
  @include get-font-styles(denver);
  color: get-color(gamma);
  margin-left: spacing(0.5);
}

.root {
  @include get-font-styles(stockholm);
  background-color: get-color(epsilon);
  background-image: url("data:image/svg+xml,<svg width='14' height='8' xmlns='http://www.w3.org/2000/svg'><path d='M7.00023 7.71299L13.0102 1.70299L11.5972 0.287994L7.00023 4.88799L2.40423 0.287994L0.990234 1.70199L7.00023 7.71299Z'/><path fill='none' d='m0,0l14,0l14,8l-14,0l0,-8z'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 14px);
  background-position-y: calc(50% + 1px);
  border: 2px solid transparent;
  border-radius: spacing(0.5);
  color: get-color(primary);
  line-height: 20px;
  padding: 9px 40px 9px 8px;
  margin-bottom: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &.bordered {
    background-color: get-color(beta);
    border: 1px solid get-color(kappa);
    line-height: 22px;
  }

  &:focus {
    border-color: get-color(alpha);
    box-shadow: 0 0 0 3px get-color(zeta);
    outline: 0;
  }

  &:hover {
    &:not(:focus) {
      border-color: get-color(zeta);
      color: #696969;
    }
  }

  &.error {
    border-color: get-color(gamma);
  }
}

label {
  @include get-font-styles(copenhagen);
  color: get-color(primary);
}

.error {
  color: get-color(gamma);
}

.required {
  @include get-font-styles(denver);
  color: get-color(gamma);
  margin-left: spacing(0.5);
}
