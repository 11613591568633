@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/functions/spacing';

@keyframes progressAnimation {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes completeAnimation {
  0% {
    left: 0%;
    right: 100%;
  }

  100% {
    left: 0%;
    right: 0%;
  }
}

.modal {
  width: 100%;

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.confirmModal {
  max-width: 448px;
}

.closeButton {
  padding-left: 0;
  padding-right: 0;
}

.row {
  display: flex;
  padding: 20px;

  + .row {
    padding-top: 0;
  }
}

.endRow {
  justify-content: flex-end;

  button {
    margin-left: spacing(2);
    padding-left: spacing(2);
    padding-right: spacing(2);
  }
}

.titleRow {
  align-items: center;
  justify-content: space-between;
}
