@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';

.root {
  position: fixed;
  right: spacing(1);
  bottom: spacing(1);
  left: spacing(1);
  z-index: 999;
  background-color: get-color(beta);
  padding: spacing(2);
  border-radius: 4px;
  box-shadow: 0 2px 12px get-color(iota);
  margin: 0 auto;
  max-width: 700px;
}
