@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.block {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: spacing(1);
  position: relative;

  &.bordered {
    border: 1px solid get-color(epsilon);
    border-radius: 4px;
    padding: spacing(2);
    width: 100%;

    &.labeled {
      margin-top: spacing(3);
    }
  }

  > div {
    margin-bottom: spacing(1);
  }
}

.root {
  @include get-font-styles(stockholm);
  color: get-color(primary);
}

.groupLabel {
  @include get-font-styles(copenhagen);
  line-height: spacing(4);
  margin-bottom: spacing(1);

  .bordered & {
    position: absolute;
    top: spacing(-4);
  }
}

.required {
  @include get-font-styles(denver);
  color: get-color(gamma);
  margin-left: spacing(0.5);
}
