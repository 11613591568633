@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/mixins/respond-to';
@import '~styles/tools/functions/str-replace';
@import '~styles/tools/functions/ease';

.root {
  background-color: get-color(psi);
  padding: spacing(6, 0);

  // Need this ugly code because we have some padding on <Layout />
  @each $breakpoint, $padding in $container-paddings {
    @if ($breakpoint == 'root') {
      margin-bottom: -#{str-replace('#{spacing($padding)}', ' ', '')};
    } @else {
      @include respond-to($breakpoint) {
        margin-bottom: -#{str-replace('#{spacing($padding)}', ' ', '')};
      };
    }
  };
}

.container {
  margin: 0 auto;
  max-width: 1128px;
  width: 100%;
}

.links {
  display: flex;
  flex-direction: column;
}

.heading,
.linkHolder {
  &:not(:last-child) {
    margin-bottom: spacing(1);
  }
}

.heading {
  @include get-font-styles(colombo);
  color: get-color(beta);
}

.link {
  @include get-font-styles(stockholm);
  color: get-color(beta);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

:global(.menuOpen) {
  &:not(:global(.menuOverlay)) {
    .root {
      margin-left: 290px;
    }
  }
}
