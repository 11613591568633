@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/functions/spacing';

.root {
  border-collapse: collapse;
  border-spacing: 0;
  flex: 1;

  &.sticky {
    border-collapse: separate;

    th {
      background-color: get-color(beta);
      left: 0;
      top: 0;
      position: sticky;
      z-index: 2;
    }
  }

  &.borderTop {
    thead {
      border-top: 1px solid get-color(epsilon);
    }
  }

  thead {
    color: get-color(eta);
    text-align: left;
  }

  tbody {
    color: get-color(primary);
  }

  th,
  td {
    border-bottom: 1px solid get-color(epsilon);
    border-left: 1px solid get-color(epsilon);
    padding: spacing(1, 2);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;

    &.noPadding {
      padding: 0;
    }
  }

  th {
    @include get-font-styles(copenhagen);
  }

  td {
    @include get-font-styles(stockholm);
  }
}

.wrapper {
  flex: 1;
  margin-bottom: -1px;
  margin-left: -1px;
  overflow-x: auto;
  position: relative;
  width: inherit;
  overflow-y: hidden;

  table {
    margin-bottom: 0;
    width: 100%;
  }
}

.wrapper .spinner {
  position: absolute;
  left: 50%;
  top: 50%;
}

.pagination,
.footer {
  margin-top: auto;
}

.pagination {
  border-top: 1px solid get-color(epsilon);
  padding: spacing(3);
}

.footer {
  padding: spacing(2);
}
