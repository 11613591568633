@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/aspect-ratio';
@import '~styles/tools/mixins/get-font-styles';

.item {
  padding: spacing(2);
  position: relative;
  color: get-color(psi);
  display: flex;
  @include get-font-styles(copenhagen);
  text-decoration: none;
  height: 84px;
  transition: ease(background-color, fast, out-quad);

  &:hover {
    background-color: get-color(epsilon);
  }

  &::after {
    content: '';
    position: absolute;
    right: spacing(2);
    bottom: 0;
    left: spacing(2);
    height: 1px;
    background-color: get-color(epsilon);
  }
}

.info {
  margin-left: spacing(2);
  flex-direction: column;
  display: flex;

  span {
    @include get-font-styles(copenhagen);
    line-height: 20px;
  }

  .articleNumber {
    color: get-color(pi);
  }
}

.price {
  margin-left: auto;
  display: flex;
  flex-direction: column;

  span {
    @include get-font-styles(copenhagen);
    line-height: 20px;
  }

  .discounted {
    @include get-font-styles(colombo);
    color: get-color(gamma);
  }

  .oldPrice {
    text-decoration: line-through;
  }
}

.image {
  @include aspect-ratio(4, 3);
  background-color: get-color(epsilon);
  max-width: 70px;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  i {
    color: get-color(eta);
    display: inline;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
    bottom: auto;
    right: auto;
    width: 24px;
    font-size: 24px;
  }
}
