@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.block {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.root {
  @include get-font-styles(stockholm);
  background-color: get-color(epsilon);
  border: 1px solid get-color(primary);
  border-radius: 2px;
  color: get-color(primary);
  line-height: 24px;
  outline: none;
  padding: 11px 15px;

  &:focus {
    border-color: get-color(alpha);
  }

  &:hover {
    border-color: get-color(alpha);
  }

  &.error {
    border-color: get-color(gamma);
  }
}

label {
  display: flex;
  align-items: center;
  color: get-color(primary);
  line-height: 32px;
}

.label {
  @include get-font-styles(stockholm);
  margin-left: spacing(1);
}

.error {
  color: get-color(gamma);
}

.description {
  margin-left: spacing(1);
  line-height: spacing(3);
  @include get-font-styles(helsinki);
}
