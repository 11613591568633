@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.main {
  background-color: get-color(lambda);
  border: 1px solid get-color(epsilon);
  border-radius: spacing(0.5);
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: spacing(2, 0);
  margin-bottom: spacing(2);
}

.whiteBackground {
  background-color: get-color(beta);
}

.reactSelectContainer {
  flex: 1;

  :global {
    .react-select__control {
      background-color: get-color(epsilon);
      border: 0;
    }

    .react-select__dropdown-indicator {
      color: get-color(primary);
    }

    .react-select__option--is-focused {
      background-color: get-color(phi);
      color: get-color(primary);
    }

    .react-select__dropdown-indicator:hover {
      color: unset;
    }
  }
}

.tableInfo {
  @include get-font-styles(rome);
  padding: spacing(0, 2, 2);
}

.infoRed {
  color: get-color(gamma);
}
