@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.root {
  position: relative;
}

.dropdown {
  z-index: 1;
  position: absolute;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  top: 10px;
  right: 20px;
  background: get-color(beta);
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);

  button {
    border: none;
    text-align: left;
    padding: spacing(1, 3, 1, 1.5);
    cursor: pointer;
    user-select: none;
  }

  button:hover {
    background-color: #f1f0f0;
  }
}
