@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.root {
  display: flex;
  flex-direction: row;
  @include get-font-styles(stockholm);
  background-color: get-color(epsilon);
  border-radius: spacing(0.5);
  color: get-color(primary);
  line-height: 20px;
  margin-bottom: 8px;

  &.bordered {
    background-color: get-color(beta);
    line-height: 22px;

    .text {
      border: 1px solid get-color(kappa);
    }
  }

  &:focus {
    box-shadow: 0 0 0 3px get-color(zeta);
    outline: 0;

    .text {
      border-color: get-color(alpha);
    }
  }

  &:hover {
    &:not(:focus) {
      color: #696969;

      .text {
        border-color: get-color(zeta);
      }
    }
  }

  &.error {
    .text {
      border-color: get-color(gamma);
    }
  }

  button {
    align-items: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    padding-left: 16px;
  }
}

label {
  @include get-font-styles(copenhagen);
  color: get-color(primary);
}

.required {
  @include get-font-styles(denver);
  color: get-color(gamma);
  margin-left: spacing(0.5);
}

.text {
  align-items: center;
  border-color: transparent;
  border-style: solid;
  border-width: 2px 2px 2px 0;
  display: flex;
  flex: 1;
  height: spacing(5);
  padding: spacing(0.5) 0 spacing(0.5) spacing(1);
}

.hidden {
  display: none;
}

.singleUploadButton {
  display: flex;
}
