@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.root {
  z-index: 9999;
  width: 400px;
  height: 400px;
  padding: spacing(3);
}

.closeButton {
  position: absolute;
  align-self: flex-end;
  padding-left: 0;
  padding-right: 0;

  i {
    transform: translateX(0) !important;
  }
}

.body {
  display: flex;
  gap: spacing(4);
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .success {
    font-size: 140px;
    color: get-color(omega);
  }

  .error {
    font-size: 140px;
    color: get-color(gamma);
  }

  .messages {
    display: flex;
    flex-direction: column;
    padding: spacing(0, 2);
    gap: spacing(2);
    text-align: center;

    .primaryMessage {
      @include get-font-styles(denver);
    }

    .secondaryMessage {
      @include get-font-styles(copenhagen);
      color: get-color(eta);
    }
  }
}
