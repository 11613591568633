@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

$light: '~assets/icons/gradient-circle-white.svg';
$dark: '~assets/icons/gradient-circle-black.svg';

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

$button-colors: (
  primary: (default: primary, text: beta, hover: theta, hoverText: beta, loader: $light),
  alpha: (default: alpha, text: beta, hover: theta, hoverText: beta, loader: $light),
  beta: (default: beta, text: primary, hover: kappa, hoverText: primary, loader: $dark),
  gamma: (default: gamma, text: beta, hover: theta, hoverText: beta, loader: $light),
  epsilon: (default: epsilon, text: primary, hover: theta, hoverText: primary, loader: $dark),
  zeta: (default: zeta, text: beta, hover: theta, hoverText: beta, loader: $light),
  eta: (default: eta, text: beta, hover: theta, hoverText: beta, loader: $light),
  theta: (default: theta, text: beta, hover: theta, hoverText: beta, loader: $light),
  iota: (default: iota, text: beta, hover: theta, hoverText: beta, loader: $dark),
  kappa: (default: kappa, text: beta, hover: theta, hoverText: beta, loader: $dark),
  lambda: (default: lambda, text: beta, hover: theta, hoverText: beta, loader: $dark),
  xi: (default: xi, text: beta, hover: theta, hoverText: beta, loader: $light),
  pi: (default: pi, text: beta, hover: theta, hoverText: beta, loader: $light),
  fi: (default: fi, text: beta, hover: theta, hoverText: beta, loader: $light),
  koppa: (default: koppa, text: beta, hover: theta, hoverText: beta, loader: $light),
  rho: (default: rho, text: beta, hover: theta, hoverText: beta, loader: $light),
  omega: (default: omega, text: beta, hover: theta, hoverText: beta, loader: $light),
);

.root {
  @include get-font-styles(stockholm);
  text-decoration: none;
  border: 2px solid;
  border-radius: 4px;
  background-color: transparent;
  line-height: map-get($line-heights, base);
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  position: relative;

  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
  }

  &:not(.noColor) {
    :global(.material-icons),
    :global(.material-icons-outlined) {
      transform: translateX(-5px);
    }

    span + :global(.material-icons),
    span + :global(.material-icons-outlined) {
      transform: translateX(3px);
    }

    :global(.material-icons) + span,
    :global(.material-icons-outlined) + span,
    span + :global(.material-icons),
    span + :global(.material-icons-outlined) {
      margin-left: 3px;
    }
  }
}

.content {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: ease(transform, fast, out-back), ease(opacity, fast, out-back), ease(visibility, 0s, out-back);

  .loading & {
    transition: ease(transform, fast, out-back), ease(opacity, fast, out-back), ease(visibility, 0s, out-back, fast);
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.smSize {
  padding: spacing(0, 1);
}

.mdSize {
  padding: spacing(0.5, 1.5);
}

.lgSize {
  padding: spacing(1.5, 5);
}

@each $name, $colors in $button-colors {
  $c: get-color(map-get($colors, default));
  $ct: get-color(map-get($colors, text));
  $h: get-color(map-get($colors, default), -0.25);
  $ht: get-color(map-get($colors, hoverText));

  .#{$name}Color {
    background-color: $c;
    border-color: $c;
    color: $ct;

    &.ghost {
      background-color: transparent;
      color: $ct;
      border-color: transparent;
    }

    &:not(.disabled) {
      &:hover {
        background-color: $h;
        border-color: $h;
        color: $ht;
      }
    }

    .loader {
      &::after {
        background-image: url('#{map-get($colors, loader)}');
      }
    }
  }
}

.epsilonColor {
  $color: map-get($colors, epsilon);
  background-color: $color;
  border-color: $color;
  color: map-get($colors, primary);

  &.ghost {
    background-color: transparent;
    color: $color;
  }

  &:not(.disabled) {
    &:hover {
      background-color: map-get($colors, iota);
      border-color: map-get($colors, iota);
      color: map-get($colors, primary);
    }
  }
}

.clearColor {
  $color: get-color(primary);
  border: 0;
  font-weight: inherit;

  &.ghost {
    color: $color;
  }
}

.noColor {
  background-color: transparent;
  color: inherit;
  border: 0;
  padding: 0;
  margin: 0;
}

.fullWidth {
  width: 100%;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  transition: ease(transform, fast, out-back), ease(opacity, fast, out-back), ease(visibility, 0s, out-back, fast);
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    height: 20px;
    width: auto;
    animation: rotate 1000ms linear infinite forwards;
  }

  &::after {
    content: '';
    background-image: url($light);
    background-size: 100%;
    position: absolute;
    width: 20px;
    height: 20px;
    animation: rotate 1000ms linear infinite forwards;
  }

  .loading & {
    transition: ease(transform, fast, out-back), ease(opacity, fast, out-back), ease(visibility, 0s, out-back);
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}
