@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.root {
  overflow: hidden;
  position: relative;
}

.header {
  padding: spacing(1.5, 2);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.headerContent {
  flex: 1;
  position: relative;
}

.toggle {
  margin-left: auto;
}

.expandButton {
  @include expand-hitarea(1);
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: spacing(2);
  font-weight: bold;
}

.body {
  height: 100%;
  position: relative;
  overflow: hidden;
  transition: ease(max-height, fast, out-quad);
}

.content {
  &:not(:empty) {
    border-top: 1px solid get-color(epsilon);
    padding: spacing(2);
  }
}

.center {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: spacing(1, 0);
}
