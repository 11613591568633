@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.test {
  min-width: 366px;
}

.react-calendar__navigation {
  position: relative;

  .react-calendar__navigation__arrow {
    &:first-of-type,
    &:last-of-type {
      display: none;
    }
    @include get-font-styles(copenhagen)
      &:hover {
      background-color: get-color(beta);
    }
  }

  .react-calendar__navigation__label {
    @include get-font-styles(copenhagen);
  }
}

.react-calendar__month-view__weekdays__weekday {
  pointer-events: none;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus,
.react-calendar__tile:enabled:hover,
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: get-color(beta);
}

.react-calendar__navigation__label {
  pointer-events: none;
}

.react-calendar__navigation button[disabled] {
  background-color: get-color(beta);
}

.react-calendar__month-view__weekdays__weekday {
  @include get-font-styles(copenhagen);
}

.react-calendar__tile--active {
  background: get-color(psi);
  color: get-color(beta);
  border-radius: 4px;
}

.react-calendar__month-view__days__day--weekend {
  color: get-color(psi);

  &:disabled {
    background-color: get-color(beta);
  }
}

.react-calendar__tile:disabled {
  background-color: get-color(beta);
  color: #b2b2b2;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: get-color(psi);
  color: get-color(beta);
}

.react-calendar__tile,
.react-calendar__month-view__days__day {
  @include get-font-styles(copenhagen);
}

.react-calendar__tile--now {
  background-color: get-color(beta);
  color: get-color(psi);
}
