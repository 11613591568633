@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/mixins/respond-to';

.root {
  background-color: get-color(beta);
  border: 1px solid get-color(epsilon);
  border-radius: spacing(0.5);
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(.overflow) {
    overflow: hidden;
  }
}

.full {
  @each $breakpoint, $padding in $container-paddings {
    @if ($breakpoint == 'root') {
      min-height: calc(100vh - #{$padding * $spacing-unit + 126});
    } @else {
      @include respond-to($breakpoint) {
        min-height: calc(100vh - #{$padding * $spacing-unit + 126});
      };
    }
  };
}

.header,
.footer {
  padding: spacing(3);

  &.thin {
    padding: spacing(1.5);
  }
}

.header {
  border-bottom: 1px solid get-color(epsilon);
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1;

  .pagination {
    margin-top: auto;
  }
}
