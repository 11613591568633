@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';

.modalRoot {
  padding: spacing(3);
}

.modalTop {
  border-bottom: 1px solid get-color(omicron);
  margin-bottom: spacing(2);
  padding-bottom: spacing(2);
}
