@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';

.root {
  position: relative;

  > button {
    line-height: 20px;
  }

  &.open {
    .options {
      visibility: visible;
      pointer-events: all;
    }
  }
}

.options {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  padding: spacing(1, 0);
  z-index: 1;
  visibility: hidden;
  pointer-events: none;
}
