@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: relative;
  pointer-events: none;

  > svg {
    width: 100%;
    height: 100%;
    animation: rotate 700ms linear infinite forwards;

    circle {
      stroke-width: 4px;
    }
  }
}
