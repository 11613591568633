h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

@each $element, $type in $heading-styles {
  #{$element} {
    @include get-font-styles($type);
  }
}
