@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.root {
  display: flex;
  padding: 0;
}

.entrypoints {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
