@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/functions/spacing';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  padding: spacing(3);
  border-top: 1px solid get-color(epsilon);
}

.column {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    @include get-font-styles(copenhagen);
    color: get-color(eta);
    line-height: 1;
  }

  button {
    margin-left: spacing(2);
  }

  .currentPage {
    margin-left: spacing(2);
    color: get-color(primary);
    white-space: nowrap;
  }
}

.jumpToPage {
  white-space: nowrap;

  > span {
    border-left: 1px solid get-color(primary);
    display: inline-block;
    margin-left: spacing(1);
    padding: spacing(0, 1);
  }
}

.pageInput {
  width: auto;

  input {
    margin: 0;
    padding: 4px 4px 3px 8px;
    width: spacing(8);
  }
}

.select {
  background-color: get-color(epsilon);
  background-image: url("data:image/svg+xml,<svg width='8' height='5' xmlns='http://www.w3.org/2000/svg'><path d='M8 2.44141e-06L4 4.76L-1.2207e-06 2.44141e-06H8Z'/><path fill='none' d='m0,0l8,0l8,5l-8,0l0,-5z'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  min-width: 60px;
  background-position-y: 14px;
  border-radius: spacing(0.5);
  border: 0;
  padding: 6px 17px 5px 12px;
  margin: 0 spacing(1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button {
  background: none;
  padding: 6px 8px 5px;

  svg {
    vertical-align: middle;
  }
}
