@function get-color($color, $saturation: 0, $alpha: 1) {
  $mix-color: if($saturation < 0, #000, #fff);
  $saturation: min(1, abs($saturation)) * 100;

  @if map-has-key($colors, $color) {
    $color: map-get($colors, $color);
  }

  @return if($alpha < 1, rgba(mix($mix-color, $color, $saturation), $alpha), mix($mix-color, $color, $saturation));
}
