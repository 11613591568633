@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/aspect-ratio';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/mixins/truncate';

.root {
  background-color: get-color(beta);
  height: 100%;
  border-radius: spacing(0.5);
  position: relative;
  display: flex;
  flex-direction: column;
}

.thumbnail {
  @include aspect-ratio(264, 180);
  background: center center/contain no-repeat;
  border-top-right-radius: spacing(0.5);
  border-top-left-radius: spacing(0.5);
}

.discount {
  user-select: none;
  @include get-font-styles(reykjavik);
  color: get-color(beta);
  padding: spacing(0.5, 1);
  background-color: get-color(gamma);
  position: absolute;
  top: spacing(2);
  right: spacing(2);
  border-radius: 4px;
}

.content {
  padding: spacing(2);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.name,
.price,
.sequence,
.status {
  @include truncate();
}

.status {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.name,
.price {
  @include get-font-styles(helsinki);
  color: get-color(primary);
  line-height: 24px;
  font-weight: 500;
}

.sequence {
  @include get-font-styles(copenhagen);
  color: get-color(pi);
}

.price {
  margin-bottom: spacing(2);
}

.priceDiscount {
  color: get-color(gamma);
}

.priceLineThrough {
  text-decoration: line-through;
  @include get-font-styles(stockholm);
  margin-left: spacing(1);
}

.amount {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
}

.amountInput {
  flex: 1;
  margin: 0 spacing(1);

  input {
    margin-bottom: 0;
    text-align: center;
    @include get-font-styles(copenhagen);
    height: 33px; // Same as button
    width: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

.amountDecrease,
.amountIncrease {
  background-color: get-color(alpha);
  display: block;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: get-color(beta);
}

.button {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
