@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/aspect-ratio';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/mixins/truncate';

.root {
  background-color: get-color(psi);
  color: get-color(beta);
  position: absolute;
  top: 100%;
  left: 50%;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.17);
  white-space: nowrap;

  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.smSize {
  transform: translate(-50%, spacing(1.5));
  @include get-font-styles(reykjavik);
  font-weight: 400;
  padding: spacing(1, 2.5);

  &::after {
    border-left: spacing(1.5) solid transparent;
    border-right: spacing(1.5) solid transparent;
    border-bottom: spacing(1) solid get-color(psi);
  }
}

.mdSize {
  transform: translate(-50%, spacing(2));
  @include get-font-styles(stockholm);
  padding: spacing(1.5, 5);

  &::after {
    border-left: spacing(2) solid transparent;
    border-right: spacing(2) solid transparent;
    border-bottom: spacing(1.5) solid get-color(psi);
  }
}
