@import '~styles/settings/variables';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/mixins/respond-to';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';

.root {
  @include get-font-styles(stockholm);
  background: none;
  color: get-color(primary);
  margin-bottom: -1px;
  overflow: hidden;
  padding-bottom: spacing(3);
  padding-top: spacing(2);
  position: relative;
  transition: color map-get($durations, base) map-get($easings, out-quad);

  @include respond-to(sm) {
    &:not(:last-child) {
      margin-right: spacing(2);
    }
  }

  &::after {
    background-color: get-color(alpha);
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transform: translateY(2px);
    transition: transform map-get($durations, base) map-get($easings, out-quad);
    width: 100%;
  }

  &.small {
    padding-bottom: spacing(0.5);
    padding-left: spacing(0);
    padding-right: spacing(0);
    margin-left: spacing(0);
    margin-right: spacing(4);
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    white-space: nowrap;
  }
}

.selected {
  color: get-color(primary);
  @include get-font-styles(copenhagen);

  &::after {
    transform: translateY(0);
  }
}
