@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/aspect-ratio';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/mixins/truncate';

.main {
  min-height: 196px;
  position: static;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 110%;
  transform: translateX(-5%);
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4%;
  width: 90%;
}

.cardWrapper {
  position: static;
  width: 22%;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 5%;
}

.spinner {
  // Size of spinner
  margin: auto;
  top: 25px;
  left: 25px;
}

.materialIconsButton {
  padding: spacing(0, 0, 0, 1);
  background-color: transparent;
  color: get-color(primary);
  border-width: 0;
}
