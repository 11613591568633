@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.modalHeader {
  border-bottom: 1px solid get-color(epsilon);
  justify-content: space-between;
  padding: spacing(3);
  display: flex;
}

.closeButton {
  padding-left: 0;
  padding-right: 0;
}

.spinner {
  // Size of spinner
  top: 25px;
  left: 25px;
}
