@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.stepper {
  display: flex;

  button {
    background-color: get-color(phi);
    border: 1px solid;
    border-radius: 4px;
    line-height: 0;
    padding: 0;
    min-width: 24px;

    > i {
      font-size: 16px;
    }
  }

  input {
    border-width: 1px;
    border-radius: 0;
    width: 100%;
    border-style: solid;
    z-index: 1;
    outline: 0;
    text-align: right;
    padding: 1px 8px 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
      width: 100%;
      text-align: center;
    }

    &:focus {
      border: 1px solid get-color(kappa);
    }
  }

  button,
  input {
    border-color: get-color(epsilon);
  }

  .minus {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  .plus {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
}
