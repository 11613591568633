@import '~styles/tools/functions/get-color';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/functions/spacing';

.informationHeading {
  color: get-color(primary);
  @include get-font-styles(denver);
  margin-bottom: spacing(1);
}

.optionItems {
  margin-bottom: spacing(2);
}

.optionItemLabel {
  @include get-font-styles(copenhagen);
  margin-bottom: 0;
}
