@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.root {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: spacing(4);
}

.headerItems {
  position: relative;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: spacing(2);
}

.bold {
  font-weight: bold;
  margin-right: spacing(0.5);
}

.backButton {
  @include get-font-styles(copenhagen);
  line-height: 6px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-self: flex-start;
  padding-left: 0;
}

.spinnerWrapper {
  position: absolute;
  display: flex;
  align-self: center;
  left: 50%;
  width: 25px;
  transform: translateX(-25px);
}

.spinner {
  // Size of spinner
  top: 25px;
  left: 25px;
}
