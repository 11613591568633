@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.root {
  background-color: get-color(lambda);
}

.main {
  margin: spacing(2, 12);
  padding-bottom: spacing(3);
}

.flexContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__space_between {
    display: flex;
    justify-content: space-between;
  }
}

.container {
  margin: spacing(2, 0);

  &__article {
    flex: 4;
  }

  &__order {
    flex: 2;
  }
}

.title {
  @include get-font-styles(rio);
  margin: spacing(0, 0, 2);

  &__sub {
    @include get-font-styles(colombo);
    margin: spacing(0, 0, 2);
  }
}

.exportButton {
  max-height: 2.5em;
}

.line {
  border-width: 1px;
  border-color: get-color(kappa);
}

.cardBox {
  flex: 1;
  min-height: 7em;
  background-color: get-color(beta);
  margin: spacing(0, 2, 2, 0);
  padding: spacing(2, 2, 1);
  border: 1px solid #eae9e9;
  box-sizing: border-box;
  border-radius: 4px;

  &__small {
    flex: 1;
    background-color: get-color(beta);
    margin: spacing(0, 2, 2, 0);
    padding: spacing(1, 2);
    border: 1px solid #eae9e9;
    box-sizing: border-box;
    border-radius: 4px;
    min-height: 3.1em;
    color: get-color(eta);
  }
}

.externallyFinanced {
  :first-child {
    @include get-font-styles(stockholm);
    margin-top: 1px;
    user-select: none;
  }
}

.grey_text {
  color: get-color(eta);
}

.backButton {
  max-height: 2em;
  max-width: 2em;
  margin: spacing(0, 1, 0, 0);
  display: flex;
  align-items: center;

  :global(.material-icons) {
    font-size: 20px;
  }
}

.errors {
  padding: 0 spacing(2);
}

.errorMessage {
  color: get-color(gamma);
}

.disabledOptionText {
  font-style: italic;
  margin: spacing(0, 0, 1, 0);
}
