@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';

.buttonWrapper {
  min-width: fit-content;
  max-width: 516px;
  margin-left: spacing(1);
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .buttonLabel {
    padding: spacing(0, 0.5);
    margin-right: spacing(1);
    border-bottom: 0.5px solid get-color(alpha);
  }
}
