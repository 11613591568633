@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/mixins/respond-to';

.root {
  position: relative;
}

.tabs {
  display: flex;
  flex-direction: column;

  @include respond-to(sm) {
    flex-direction: row;

    &:not(:last-child) {
      margin-right: 56px;
    }
  }
}
