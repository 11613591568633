@import '~styles/tools/functions/spacing';

.pagination {
  padding-top: spacing(3);
}

.spinnerHolder {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: spacing(10, 0);
}

.spinner {
  // Size of spinner
  top: 25px;
  left: 25px;
}
