@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/respond-to';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/mixins/truncate';
@import '~styles/tools/mixins/aspect-ratio';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: spacing(4);
  }
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  flex: 1;
  flex-wrap: wrap;
}

.thumbnail {
  @include aspect-ratio(99, 72);
  width: 99px;
  min-width: 99px;
  background: center center/contain no-repeat;
  margin-right: spacing(1);
}

.summarize {
  flex: 1;
  min-width: 0;
  padding-right: spacing(1);
  width: 150px;
}

.title {
  @include get-font-styles(colombo);
  color: get-color(primary);
  margin: 0;
}

.articleNumber {
  @include get-font-styles(copenhagen);
  color: get-color(pi);
}

.price {
  @include get-font-styles(copenhagen);
  color: get-color(primary);
  margin-bottom: spacing(0.25);
}

.discountedPrice {
  color: get-color(gamma);
}

.priceLineThrough {
  text-decoration: line-through;
  margin-left: spacing(2);
  font-weight: 400;
}

.title,
.articleNumber,
.price,
.status {
  @include truncate();
}

.stepper {
  width: 90px;
}

.rightFlex {
  display: flex;
  gap: spacing(1);
  flex-direction: column;
  align-items: flex-end;
}
