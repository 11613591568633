@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.root {
  background-color: get-color(lambda);
}

.main {
  margin: 0 auto;
  max-width: 1128px;
  width: 100%;
  padding-bottom: spacing(3);
}

.header,
.filter {
  @include get-font-styles(helsinki);
  margin-bottom: spacing(3);
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  align-items: center;
}
