@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/aspect-ratio';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.root {
  position: relative;

  &.full {
    @include aspect-ratio(1440, 447);
    flex: 1 0 100%;
  }

  &.half {
    @include aspect-ratio(720, 447);
    flex: 1 1 50%;
  }

  > div {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
    padding: spacing(3);
  }
}

.image {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.label {
  @include get-font-styles(rio);
  align-items: center;
  background-color: get-color(beta, 0, 0.2);
  border-radius: 4px;
  color: get-color(beta);
  display: flex;
  padding: spacing(1, 2, 1, 3);
  position: relative;

  i {
    font-size: 40px;
  }
}
