@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/aspect-ratio';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/mixins/truncate';

.name {
  margin-bottom: spacing(1);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nameText {
  color: get-color(primary);
  @include get-font-styles(rio);
}

.nameDiscount {
  user-select: none;
  @include get-font-styles(reykjavik);
  color: get-color(beta);
  padding: spacing(0.5, 1);
  background-color: get-color(gamma);
  border-radius: 4px;
  margin-left: spacing(2);
}

.sequence {
  color: get-color(eta);
  @include get-font-styles(copenhagen);
  margin-bottom: spacing(2);
}

.thumbnail {
  position: relative;
}

.thumbnailButton {
  display: block;
}

.thumbnailImage {
  width: 100%;
  display: block;
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: get-color(lambda);
  padding: spacing(3);
  min-height: 100%;
}

.informationLower {
  margin-top: spacing(2);
}

.informationDescription {
  color: get-color(eta);
  @include get-font-styles(stockholm);
  margin-bottom: spacing(2);
}

.price {
  display: flex;
  flex-direction: column;
  color: get-color(primary);
  @include get-font-styles(rio);
  margin-bottom: spacing(1);
}

.discount .priceSale {
  color: get-color(gamma);
}

.priceLineThrough {
  text-decoration: line-through;
  @include get-font-styles(denver);
  font-weight: 400;
}

.priceVat {
  @include get-font-styles(colombo);
  font-weight: 400;
  color: get-color(eta);
  margin-bottom: spacing(2);
}

.status {
  $circle-size: spacing(1.5);

  color: get-color(primary);
  @include get-font-styles(copenhagen);
  position: relative;
  padding-left: calc(#{$circle-size} + #{spacing(1)});
  margin-bottom: spacing(4);

  &::before {
    content: '';
    display: block;
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    position: absolute;
    top: calc(#{$circle-size / 2});
    left: 0;
    background-color: get-color(primary);
  }
}

.status.SWEDEN_WAREHOUSE {
  &::before {
    background-color: get-color(omega);
  }
}

.status.INCOMING {
  &::before {
    background-color: get-color(status-info-dark);
  }
}

.status.ORDERABLE {
  &::before {
    background-color: get-color(gamma);
  }
}

.amount {
  display: flex;
  flex-direction: row;
  position: relative;
}

.amountInput {
  flex: 1;
  margin: 0 spacing(2);

  input {
    margin-bottom: 0;
    text-align: center;
    background-color: get-color(beta);
    @include get-font-styles(denver);
    height: 49px; // Same as button
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

.amountDecrease,
.amountIncrease {
  background-color: get-color(alpha);
  display: block;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: get-color(beta);
}

.button {
  overflow: hidden;
}

.stockText {
  @include get-font-styles(copenhagen);
}

.vehicleCampaignText {
  @include get-font-styles(copenhagen);
  margin-top: spacing(4);
}
