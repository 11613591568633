@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

.block {
  background-color: get-color(beta);
  border: 1px solid get-color(epsilon);
  border-radius: spacing(0.5);
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: spacing(4);
  max-height: 580px;

  h1 {
    margin: spacing(3, 0);
  }
}

.text {
  max-width: 548px;
}
