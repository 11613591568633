@import '~styles/settings/variables';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';

.block {
  display: inline-flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.root {
  @include get-font-styles(stockholm);
  background-color: get-color(epsilon);
  border: 2px solid transparent;
  border-radius: spacing(0.5);
  color: get-color(primary);
  line-height: 20px;
  padding: spacing(1) spacing(1.5);
  margin-bottom: 16px;
  width: 100%;
  height: 200px;
  max-width: 100%;

  &.bordered {
    background-color: get-color(beta);
    border: 1px solid get-color(kappa);
    line-height: 22px;
  }

  &:focus {
    border-color: get-color(alpha);
    box-shadow: 0 0 0 3px get-color(zeta);
    outline: 0;
  }

  &:hover {
    &:not(:focus) {
      border-color: get-color(zeta);
      color: #696969;
    }
  }

  &.error {
    border-color: get-color(gamma);
  }
}

label {
  @include get-font-styles(copenhagen);
  color: get-color(primary);
}

.required {
  @include get-font-styles(denver);
  color: get-color(gamma);
  margin-left: spacing(0.5);
}

.error {
  color: get-color(gamma);
}

.required {
  @include get-font-styles(denver);
  color: get-color(gamma);
  margin-left: spacing(0.5);
}
