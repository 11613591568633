@import '~styles/settings/variables';
@import '~styles/tools/functions/ease';
@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/expand-hitarea';
@import '~styles/tools/mixins/get-font-styles';

:global {
  .pane {
    background-color: get-color(beta);
    border-radius: spacing(0.5);
  }
}

@keyframes enter-left {
  0% { opacity: 0;
    transform: translateX(-20px);
  }

  100% { opacity: 1;
    transform: translateX(0);
  }
}
@keyframes enter-right {
  0% { opacity: 0;
    transform: translateX(20px);
  }

  100% { opacity: 01;
    transform: translateX(0);
  }
}
@keyframes exit-left {
  0% { opacity: 1;
    transform: translateX(0);
  }

  99% { opacity: 0;
    transform: translateX(20px);
  }

  100% { opacity: 0;
    height: 0;
    overflow: hidden;
  }
}
@keyframes exit-right {
  0% { opacity: 1;
    transform: translateX(0);
  }

  99% { opacity: 0;
    transform: translateX(-20px);
  }

  100% { opacity: 0;
    height: 0;
    overflow: hidden;
  }
}

.root {
  display: flex;
  flex-direction: column;
}

.wrapper {
  margin-left: spacing(3);
  max-width: 1176px;
  margin: 0 auto;
  padding: spacing(0, 6);
  position: relative;
}

.close {
  line-height: 0;
  padding: 0;
  position: absolute;
  right: spacing(3);
  top: spacing(3.5);
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: spacing(3, 0);
}

:global(.wiz-back),
:global(.wiz-next) {
  @include get-font-styles(helsinki);
  font-weight: 500;
  display: flex;
  padding: spacing(0.5, 0);

  i {
    transform: translateX(-6px);
  }

  span + i {
    transform: translateX(6px);
  }
}

.titleWrapper {
  height: 72px;
  padding: spacing(3, 0, 2);
}

.title {
  margin: 0;
}

.header {
  background-color: get-color(beta);
  border-bottom: 1px solid get-color(epsilon);

  .sticky & {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1;
    transition: ease(margin-left, fast, out-quad);

    :global(.menuOpen) & {
      margin-left: 290px;
    }
  }
}

.body {
  .sticky & {
    padding-top: 190px;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tab {
  @include get-font-styles(reykjavik);
  @include expand-hitarea(1);
  background: none;
  border: 0;
  padding: spacing(1, 0);

  &:not(.active):not(:hover) {
    color: get-color(eta);
  }
}

.bar {
  height: spacing(0.5);
  background-color: get-color(zeta);
}

.progress {
  height: spacing(0.5);
  background-color: get-color(alpha);
  transform-origin: left;
  transform: scaleX(0);
  transition: ease(transform, fast, out-quad);
}

.slides {
  max-width: 1128px;
  margin: 0 auto;
  position: relative;
}

.slide {
  $ease: map-get($easings, out-quad);
  $duration: map-get($durations, medium);
  $delay: map-get($durations, fast);
  padding: spacing(3);
  position: absolute;
  float: left;
  width: 100%;

  &:not(.active) {
    visibility: hidden;
    height: 0;
    overflow: hidden;
    pointer-events: none;
  }

  &.enter-left {
    opacity: 0;
    animation: enter-left $ease $duration forwards $delay;
  }

  &.enter-right {
    opacity: 0;
    animation: enter-right $ease $duration forwards $delay;
  }

  &.exit-left {
    animation: exit-left $ease $duration forwards;
  }

  &.exit-right {
    animation: exit-right $ease $duration forwards;
  }
}
