@import '~styles/tools/functions/get-color';
@import '~styles/tools/functions/spacing';
@import '~styles/tools/mixins/get-font-styles';
@import '~styles/tools/mixins/aspect-ratio';

.searchBar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.select {
  flex: 0;

  select {
    border-color: get-color(kappa);
    border-width: 1px 0 1px 1px;
    border-radius: 4px 0 0 4px;
    margin: 0;
    padding: 9px 40px 9px 14px;
  }
}

.search {
  flex: 1 0;
  width: 100%;

  input {
    border-radius: 0 4px 4px 0;
    border-left-width: 0 !important;
    margin-bottom: 0;
  }

  .clear {
    margin-right: spacing(0.75);
    line-height: 0;
  }
}

.spinner {
  padding: 14px;
  top: 10px;
  left: 40px;
}

.spyglassFound {
  fill: get-color(alpha);
}

.searchResults {
  width: 100%;
  position: absolute;
  background-color: get-color(beta);
  z-index: 999;
  top: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.17);
  margin-top: spacing(0.5);
}

.linkWrapper {
  width: 100%;
  display: flex;
  padding: spacing(2);
}

.link {
  margin: auto;
  @include get-font-styles(copenhagen);
  text-decoration: none;
}

.notFound {
  margin: auto;
  color: get-color(primary);
  @include get-font-styles(copenhagen);
}
